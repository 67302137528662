<template>
  <v-dialog v-model="dialog" v-if="dialog" width="600px">
    <v-flex>
      <perseu-card :title="titleDialog">
        <v-form ref="formUser" slot="content">
          <v-text-field
            :rules="[$rules.required]"
            v-model="user.name"
            label="Nome"
            prepend-icon="person"
          />
          <v-text-field
            :rules="[$rules.email]"
            v-model="user.mail"
            prepend-icon="mail"
            label="E-mail"
          />
   
          <v-text-field
          v-if="user.id"
            :rules="[$rules.cpf]"
            v-mask="maskDocument"
            v-model="user.userData.document"
            prepend-icon="description"
            label="Documento"
          />
          <v-text-field
            v-model="user.password"
            type="password"
            label="Senha"
            prepend-icon="lock"
            name="password"
            :rules="!isEditing ? [$rules.required] : []"
            autocomplete="new-password"
          />
        
          <v-autocomplete
            :rules="[$rules.required]"
            v-model="user.roles"
            :items="roles"
            item-text="name"
            multiple
            chips
            label="Permissões"
            prepend-icon="settings"
            return-object
          />
        </v-form>
        <template slot="actions">
          <v-btn @click="save" color="secondary" :disabled="loading"
          > Salvar </v-btn>
        </template>
      </perseu-card>
    </v-flex>
  </v-dialog>
</template>

<script>
import { create } from "@/services/users-service";
import { getOne, update } from "@/services/users-management-service";
import { findAll as findAllRoles } from "@/services/roles-service";
import nationalities from "@/misc/nationalities";
export default {
  data: () => ({
    user: {},
    password: "",
    roles: [],
    dialog: false,
    loading: false,
  }),
  computed: {
    titleDialog() {
      return this.user.id ? "Editar usuário" : "Novo usuário";
    },
    nationalities() {
      return nationalities;
    },
    maskDocument() {
      if (this.user && this.user.nationality === 0) {
        return "###.###.###-##";
      }
      return "###########";
    },
    isEditing() {
      return !!this.user.id;
    },
  },
  created() {
    this.loadRoles();
  },
  methods: {
    create() {
      this.clear();
      this.dialog = true;
    },
    async edit(id) {
      const { data } = await getOne(id);
      this.user = {...data,userData: data.userData || {}};
      this.dialog = true;
    },
    async save() {
      
      let valid = this.$refs.formUser.validate();
      if (valid) {
        try {
          this.loading = true;
          if (this.user.id) {
            const { data } = await update(this.user);
            delete data.password;
            this.$emit("update", {...data,userData: data.userData || {}});
          } else {
            const { data } = await create({...this.user,document:this.user.userData.document, confirmPassword: this.user.password, roles: this.user.roles.map((role) => role.slug)});
            delete data.password;
            this.$emit("create", data);
          }
          this.$toasted.global.defaultSuccess();
          this.dialog = false;
          this.clear();
        } catch (error) {
          this.$toasted.global.defaultError();
        }finally{
          this.loading = false;
        }
      }
    },
    clear() {
      this.user = {
        userData: {},
        roles: [],
      };
    },
    async loadRoles() {
      const { data } = await findAllRoles();
      this.roles = data;
    },
  },
};
</script>

<style></style>
