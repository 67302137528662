<template>
  <div class="container">
    <div class="mb-3 d-flex justify-space-between">
      <span class="title">Cadastro de usuários</span>
      <v-btn color="primary" slot="title-right" @click="create">
        <v-icon class="mr-2">add_circle</v-icon>
        Novo Usuário
      </v-btn>
    </div>
    <filters-panel>
      <v-form ref="filters">
        <v-row>
          <v-col xl="1" md="2" cols="12">
            <v-switch
              v-model="filters.active"
              label="Ativos"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>
          <v-col xl="3" md="5" sm="6" cols="12">
            <v-text-field
              hide-details
              v-model="filters.name"
              filled
              label="Nome"
            />
          </v-col>
          <v-col xl="3" md="5" sm="6" cols="12">
            <v-text-field
              hide-details
              v-model="filters.mail"
              filled
              label="E-mail"
            />
          </v-col>
          <v-col xl="2" md="4" sm="6" cols="12">
            <v-text-field
              hide-details
              v-model="filters.document"
              filled
              label="Documento"
            />
          </v-col>
          <v-col xl="2" md="4" sm="6" cols="12">
            <select-roles filled label="Permissão" v-model="filters.roles" />
          </v-col>
        </v-row>
      </v-form>
      <v-row justify="end">
        <v-btn color="secondary" @click="list"> Filtrar </v-btn>
      </v-row>
    </filters-panel>
    <v-divider class="mb-3"></v-divider>
    <v-data-table :items="users" :headers="headers" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <table-action-button text="Editar" @click="edit(item)" icon="edit" />
        <table-action-button
          v-if="item.active"
          text="Desativar"
          @click="activeOrDeactivate(item, false)"
          icon="delete"
        />
        <table-action-button
          v-else
          text="Ativar"
          @click="activeOrDeactivate(item, true)"
          icon="check"
        />
      </template>
    </v-data-table>

    <form-user ref="formUser" @update="updateUser" @create="addToList">
    </form-user>
  </div>
</template>

<script>
import { findAll } from "@/services/users-management-service";
import FormUser from "@/components/Users/FormUser";
import { toggleActive } from "@/services/users-management-service";
export default {
  components: {
    "form-user": FormUser,
    "select-roles": () => import("@/components/Roles/Select"),
  },
  data: () => ({
    users: [],
    user: {
      active: true,
    },
    headers: [
      { text: "Nome", value: "name" },
      { text: "E-mail", value: "mail" },
      { text: "Documento", value: "userData.document" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        width: 200,
        align: "left",
      },
    ],
    dialog: false,
    filters: {
      active: 1,
    },
  }),
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let { data } = await findAll(this.filters);
        this.users = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.formUser.create();
    },
    edit({ id }) {
      this.$refs.formUser.edit(id);
    },
    async activeOrDeactivate(selectedUser, active) {
      try {
        this.$store.dispatch("loading/openDialog");
        selectedUser.active = active;
        this.user = selectedUser;
        await toggleActive(this.user.id);
        this.removeUserList(selectedUser);
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeUserList(user) {
      let index = this.users.indexOf(user);
      this.users.splice(index, 1);
    },
    addToList(user) {
      this.users.push(user);
      this.dialog = false;
    },
    updateUser(user) {
      this.list();
      this.dialog = false;
    },
  },
};
</script>

<style></style>
