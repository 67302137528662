import api from "@/services/api-service";

const baseUrl = "users-management";

export const findAll = (filters) => {
  return api().post(`/${baseUrl}/find-all`, filters);
};

export const update = (user) => api().put(`${baseUrl}/admin/${user.id}`, user);

export const getOne = (id) => api().get(`${baseUrl}/${id}`);

export const toggleActive = (userId) => {
  return api().patch(`/${baseUrl}/${userId}/toggle-active`);
};
